import slug from "slug"

const extendBlocksWithSlugs = (blocks = []) => {
  slug.setLocale("de")
  const filteredBlocks = blocks.filter(block => {
    return Object.keys(block).length > 0
  })
  const blocksWithSlugs = filteredBlocks.map(block => {
    if (block.isAnchor) {
      return {
        ...block,
        slug: slug(block.titleAnchor),
      }
    }
    return block
  })

  const anchors = blocks
    .filter(block => block.isAnchor)
    .map(anchor => {
      return { ...anchor, slug: slug(anchor.titleAnchor) }
    })
  const hasAnchors = anchors.length > 0

  return [blocksWithSlugs, anchors, hasAnchors]
}

export default extendBlocksWithSlugs
