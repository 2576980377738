import React from "react"
import PropTypes from "prop-types"
import "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"
import Copyright from "../copyright"

const MediaCard = ({ image, title, url }) => {
  const contentImage = {
    data: image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: image?.altText || ``,
  }
  const hasCopyright =
    image?.imageCopyright.photographer || image?.imageCopyright.rights

  return (
    <figure>
      <div tw="relative mb-4 bg-sand-500 aspect-ratio aspect-ratio-4/3">
        {!!hasCopyright && (
          <Copyright
            photographer={image?.imageCopyright.photographer}
            rights={image?.imageCopyright.rights}
          />
        )}
        {contentImage?.data && (
          <GatsbyImage
            image={contentImage.data}
            alt={contentImage.alt}
            tw="object-contain aspect-ratio-item"
            load="lazy"
          />
        )}
      </div>
      <figcaption tw="flex items-start text-lg font-bold lg:text-xl">
        <a href={url} title={title}>
          <span>{title}</span>
        </a>
      </figcaption>
    </figure>
  )
}

MediaCard.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export default MediaCard
