import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import "twin.macro"
import Spacing from "../spacing"
import "../../css/gutenberg.css"
import Container from "../container"

const Teaser = ({
  id,
  title,
  content,
  image,
  space,
  isCustomSpace,
  link,
  indent,
}) => {
  const teaserImage = {
    data: image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: image?.altText || ``,
  }

  return (
    <Spacing id={id} space={isCustomSpace && space}>
      {!!indent && (
        <Container>
          <div tw="grid grid-cols-12">
            <div tw="col-span-12 col-start-1 md:col-span-12 lg:col-span-12 lg:col-start-3 xl:col-span-9 xl:col-start-3">
              <div tw="flex flex-wrap justify-items-stretch">
                <div tw="flex w-full flex-col items-start bg-sand-500 p-6 md:w-1/2 lg:p-10">
                  <h3 tw="mb-3 text-2xl font-bold hyphens-auto lg:mb-5 lg:text-4xl">
                    {title}
                  </h3>
                  <p tw="mb-10 text-lg sm:text-xl xl:mb-16">{content}</p>
                  <a
                    className="wp-block-button__link"
                    href={link.url}
                    target={link.target && "_blank"}
                    tw="mt-auto inline-block bg-lilac-500 px-7 py-2.5 text-lg font-bold uppercase !leading-snug tracking-widest text-anthracite-500 no-underline transition-colors duration-300 hyphens-auto word-break hover:bg-lilac-300 focus:bg-lilac-300 sm:text-xl"
                  >
                    {link.title}
                  </a>
                </div>
                <div tw="order-first w-full md:order-last md:w-1/2">
                  <div tw="h-full w-full">
                    {teaserImage?.data && (
                      <GatsbyImage
                        image={teaserImage.data}
                        alt={teaserImage.alt}
                        tw="h-full w-full object-contain"
                        load="lazy"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
      {/* {!indent && (
        <div tw="grid grid-cols-12">
          <div tw="col-span-12 md:col-span-9 md:col-start-2">
            <div tw="w-full xl:w-1/2 bg-sand-500 p-6 lg:p-10 flex flex-col items-start">
              <h3 tw="font-bold text-2xl lg:text-4xl mb-5 hyphens-auto">
                {title}
              </h3>
              <p tw="text-lg md:text-xl mb-10 xl:mb-16">
                {content}
              </p>
              <a
                className="wp-block-button__link"
                href={link.url}
                target={link.target && "_blank"}
                tw="font-bold inline-block bg-lilac-500 px-7 py-2 uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300 text-lg md:text-xl"
              >
                {link.title}
              </a>
            </div>
            <div tw="w-full xl:w-1/2 order-first xl:order-last">
              <div tw="aspect-ratio aspect-ratio-9/5 xl:aspect-ratio-auto xl:h-full xl:w-full">
                <div tw="aspect-ratio-item">
                  {teaserImage?.data && (
                    <GatsbyImage
                      image={teaserImage.data}
                      alt={teaserImage.alt}
                      tw="h-full w-full object-contain"
                      load="lazy"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </Spacing>
  )
}

Teaser.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  link: PropTypes.object,
  image: PropTypes.object,
  id: PropTypes.string,
  space: PropTypes.string,
  isCustomSpace: PropTypes.bool,
}

export default Teaser
