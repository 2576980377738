import React from "react"
import tw from "twin.macro"
import PropTypes from "prop-types"

const BlockTitle = ({ title, tag = "h3", style = null }) => {
  const titleStyles = [
    style == null && tw`mb-4 max-w-3xl font-bold text-xl md:mb-7 md:text-2xl`,
    style === "large" &&
      tw`mb-6 max-w-3xl font-bold text-3xl lg:mb-9 lg:text-5xl`,
    style === "medium" &&
      tw`mb-4 max-w-3xl font-bold text-2xl lg:mb-3 lg:text-3xl`,
    style === "small" &&
      tw`mb-3 max-w-3xl font-bold text-xl md:mb-2 md:text-2xl`,
  ]
  return (
    <>
      {tag === "h1" && <h1 css={[titleStyles]}>{title}</h1>}
      {tag === "h2" && <h2 css={[titleStyles]}>{title}</h2>}
      {tag === "h3" && <h3 css={[titleStyles]}>{title}</h3>}
      {tag === "h4" && <h4 css={[titleStyles]}>{title}</h4>}
      {tag === "h5" && <h5 css={[titleStyles]}>{title}</h5>}
      {tag === "h6" && <h6 css={[titleStyles]}>{title}</h6>}
      {tag === null && <h3 css={[titleStyles]}>{title}</h3>}
    </>
  )
}

BlockTitle.propTypes = {
  title: PropTypes.string.isRequired,
  tag: PropTypes.string,
  style: PropTypes.string,
}

export default BlockTitle
