import React, { useState } from "react"
import tw from "twin.macro"
import parse from "html-react-parser"
import uniqueId from "lodash/uniqueId"
// import Indent from "../indent"
import plus from "../../images/svgs/plus.svg"
import minus from "../../images/svgs/minus.svg"
import Icon from "../icon"
import "../../css/gutenberg.css"
import Spacing from "../spacing"
import PropTypes from "prop-types"

const AccordionSubtitle = ({ accordion, space, background, id }) => {
  const plusIcon = plus
  const minusIcon = minus

  const [accordionItems, setAccordionItems] = useState(
    accordion.map(({ id, title, subtitle, content }) => ({
      id: id || uniqueId("accordion-"),
      title: title,
      subtitle: subtitle,
      content: content,
      isOpen: false,
    }))
  )

  const toggleAccordionItems = index => {
    setAccordionItems(
      accordionItems.map((accordionItem, i) => {
        if (index === i) {
          accordionItem.isOpen = !accordionItem.isOpen
        }
        return accordionItem
      })
    )
  }

  return (
    <Spacing
      space={space}
      defaultStyles={tw`mb-20 sm:mb-22 md:mb-26 lg:mb-32`}
      background={background}
      id={id}
    >
      <ul tw="focus-within:bg-lightgray-500/40">
        {accordionItems.map(
          ({ id, title, subtitle, content, isOpen }, index) => (
            <li
              key={id}
              css={[
                tw`flex flex-col border-t-3`,
                index === 0 && tw`border-t-honey-500`,
                index === 1 && tw`border-t-magenta-500`,
                index === 2 && tw`border-t-mint-500`,
                index === 3 && tw`border-t-lilac-500`,
              ]}
            >
              <button
                className="group"
                css={[
                  tw`flex flex-nowrap items-center px-4 lg:px-6 `,
                  index === 0 && tw`border-t-honey-500 focus:bg-honey-50 `,
                  index === 1 && tw`border-t-magenta-500 focus:bg-magenta-50`,
                  index === 2 && tw`border-t-mint-500 focus:bg-mint-50`,
                  index === 3 && tw`border-t-lilac-500 focus:bg-lilac-50`,
                ]}
                id={`${id}-header`}
                aria-controls={`${id}-panel`}
                aria-expanded={isOpen}
                onClick={() => toggleAccordionItems(index)}
              >
                <div tw="flex flex-grow flex-wrap">
                  <div tw="block w-7/12 pt-4 pr-2 text-left text-xl font-bold lg:w-3/12 lg:py-6">
                    {title}
                  </div>
                  <div tw="w-5/12 py-4 lg:flex lg:w-2/12 lg:justify-center lg:py-6 lg:pr-2">
                    <div
                      css={[
                        tw`mt-3 w-12 border-t-3`,
                        index === 0 && tw`border-honey-500`,
                        index === 1 && tw`border-magenta-500`,
                        index === 2 && tw`border-mint-500`,
                        index === 3 && tw`border-lilac-500`,
                      ]}
                    />
                  </div>
                  <div tw="w-full pb-4 text-left text-xl font-bold lg:w-7/12 lg:py-6">
                    {subtitle}
                  </div>
                </div>
                <div tw="flex flex-shrink-0 items-start justify-end py-4 pl-6">
                  <div
                    css={[
                      tw`flex h-10 w-10 items-center justify-center md:h-9 md:w-9 `,
                      index === 0 &&
                        tw`group-hover:bg-honey-500 group-focus:bg-honey-500`,
                      index === 1 &&
                        tw`group-hover:bg-magenta-500 group-focus:bg-magenta-500`,
                      index === 2 &&
                        tw`group-hover:bg-mint-500 group-focus:bg-mint-500`,
                      index === 3 &&
                        tw`group-hover:bg-lilac-500 group-focus:bg-lilac-500`,
                    ]}
                  >
                    <Icon
                      title={isOpen ? "Details Verbergen" : "Details Anzeigen"}
                      svg={isOpen ? minusIcon : plusIcon}
                      css={[
                        tw`h-6 w-6 md:h-5 md:w-5`,
                        index === 0 &&
                          tw`text-honey-500 group-hover:text-white group-focus:text-white`,
                        index === 1 &&
                          tw`text-magenta-500 group-hover:text-white group-focus:text-white`,
                        index === 2 &&
                          tw`text-mint-500 group-hover:text-white group-focus:text-white`,
                        index === 3 &&
                          tw`text-lilac-500 group-hover:text-white group-focus:text-white`,
                      ]}
                    />
                    <span tw="sr-only">
                      {isOpen ? "Details Verbergen" : "Details Anzeigen"}
                    </span>
                  </div>
                </div>
              </button>
              <div
                id={`${id}-panel`}
                aria-labelledby={`${id}-header`}
                hidden={isOpen ? "" : true}
                tw="bg-white px-4 pb-4 pt-4 lg:px-6 lg:pb-6 lg:pt-6"
              >
                <div tw="justify-end lg:flex lg:pr-16">
                  <div tw="mb-10 md:mb-0 lg:w-7/12" className="wp-content">
                    {parse(content)}
                  </div>
                </div>
              </div>
            </li>
          )
        )}
      </ul>
    </Spacing>
  )
}

AccordionSubtitle.propTypes = {
  space: PropTypes.string,
  id: PropTypes.string,
}

export default AccordionSubtitle
