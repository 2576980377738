import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import parse from "html-react-parser"
import Spacing from "../spacing"
import Container from "../container"
import Indent from "../indent"
import BlockTitle from "../block-title"

const Text = ({
  title,
  titleTag,
  titleStyle,
  titleAnchor,
  content,
  space,
  isCustomSpace,
  isCustomBackground,
  isCustomTitleStyle,
  isCustomTitleTag,
  isAnchor,
  background,
  id,
  indent,
}) => {
  return (
    <Spacing
      space={isCustomSpace && space}
      defaultStyles={tw`mb-8 sm:mb-12 md:mb-16 lg:mb-20`}
      background={isCustomBackground && background}
      id={id}
    >
      {!!indent && !!title && (
        <Container>
          <BlockTitle
            title={title}
            tag={isCustomTitleTag && titleTag}
            style={isCustomTitleStyle && titleStyle}
            anchor={isAnchor && titleAnchor}
          />
        </Container>
      )}
      {!indent && !!title && (
        <BlockTitle
          title={title}
          tag={isCustomTitleTag && titleTag}
          style={isCustomTitleStyle && titleStyle}
          anchor={isAnchor && titleAnchor}
        />
      )}
      {!!indent && !!content && (
        <Container>
          <Indent>
            <section itemProp="articleBody" className="wp-content">
              {parse(content)}
            </section>
          </Indent>
        </Container>
      )}
      {!indent && !!content && (
        <section itemProp="articleBody" className="wp-content">
          {parse(content)}
        </section>
      )}
    </Spacing>
  )
}

Text.propTypes = {
  id: PropTypes.string,
  space: PropTypes.string,
  titleStyle: PropTypes.string,
  titleTag: PropTypes.string,
  titleAnchor: PropTypes.string,
  isCustomSpace: PropTypes.bool,
  isCustomBackground: PropTypes.bool,
  isCustomTitleStyle: PropTypes.bool,
  isCustomTitleTag: PropTypes.bool,
  isAnchor: PropTypes.bool,
}

export default Text
