import React from "react"
import { Player, Controls } from "@lottiefiles/react-lottie-player"
import PropTypes from "prop-types"
import Spacing from "../spacing"
import { GatsbyImage } from "gatsby-plugin-image"
import "twin.macro"

const Animation = ({
  id,
  animation,
  animationDescription,
  space,
  isCustomSpace,
  animationFallbackImage,
}) => {
  const fallback = {
    data: animationFallbackImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: animationFallbackImage?.altText || ``,
  }

  return (
    <Spacing id={id} space={isCustomSpace && space}>
      <div
        tw="aspect-ratio aspect-ratio-9/5"
        role="img"
        aria-label={animationDescription}
      >
        <div tw="aspect-ratio-item" aria-hidden="true">
          {fallback?.data && (
            <GatsbyImage
              image={fallback.data}
              alt={fallback.alt}
              tw="hidden w-full motion-reduce:block "
              load="lazy"
            />
          )}
          <Player
            autoplay
            loop
            src={animation}
            tw="w-full motion-reduce:hidden"
            id="lotti-mobile"
          >
            <Controls visible={false} />
          </Player>
        </div>
      </div>
    </Spacing>
  )
}

Animation.propTypes = {
  animation: PropTypes.string,
  animationDescription: PropTypes.string,
  animationFallbackImage: PropTypes.object,
  id: PropTypes.string,
  space: PropTypes.string,
  isCustomSpace: PropTypes.bool,
}

export default Animation
