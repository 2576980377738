import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
// import Obfuscate from "react-obfuscate"
import tw from "twin.macro"
import Copyright from "../copyright"
import DescriptionModal from "../description-modal"
import { callablePhoneNumber } from "../../helpers/phone-number"

const TeamMemberCard = ({
  member: {
    title,
    content,
    featuredImage,
    member: { phone, email, jobtitle },
  },
}) => {
  const image = {
    data: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImage?.node?.altText || ``,
  }
  const hasCopyright =
    featuredImage?.node?.imageCopyright.photographer ||
    featuredImage?.node?.imageCopyright.rights

  return (
    <div tw="flex flex-col bg-lilac-500">
      {image?.data && (
        <div tw="relative aspect-ratio aspect-ratio-4/3">
          {!!hasCopyright && (
            <Copyright
              photographer={featuredImage?.node?.imageCopyright.photographer}
              rights={featuredImage?.node?.imageCopyright.rights}
            />
          )}
          <GatsbyImage
            image={image.data}
            alt={image.alt}
            tw="object-cover aspect-ratio-item"
          />
        </div>
      )}
      {image?.data == null && (
        <div tw="aspect-ratio aspect-ratio-4/3">
          <div tw="aspect-ratio-item">
            <StaticImage
              src="../../images/placeholder_4_3.png"
              alt=""
              tw="h-full w-full object-cover"
            />
          </div>
        </div>
      )}
      <div tw="flex flex-grow flex-col justify-start p-4 lg:p-5">
        <div tw="mb-3">
          <div tw="text-xl font-bold lg:text-2xl">{title}</div>
        </div>
        <div tw="flex flex-grow flex-col font-regular text-lg lg:text-xl">
          <div tw="mb-4">{jobtitle}</div>

          <div
            css={[
              tw`mt-auto flex flex-col items-start`,
              !content && tw`mb-12 lg:mb-14`,
            ]}
          >
            {/* <Obfuscate tel={phone} tw="text-link-negative" /> */}
            <a
              href={"tel:" + callablePhoneNumber(phone)}
              tw="text-link-negative"
            >
              {phone}
            </a>
            <a href={"mailto:" + email} tw="text-link-negative">
              Mail
            </a>
          </div>
        </div>
      </div>
      <div tw="ml-auto mt-auto w-min">
        {!!content && <DescriptionModal title={title} content={content} />}
      </div>
    </div>
  )
}

TeamMemberCard.propTypes = {}

export default TeamMemberCard

export const pageQuery = graphql`
  fragment TeamFragment on WpMember {
    id
    title
    member {
      email
      firstname
      jobtitle
      lastname
      phone
      title
    }
    content
    featuredImage {
      node {
        altText
        imageCopyright {
          photographer
          rights
        }
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 60
              placeholder: BLURRED
              layout: FULL_WIDTH
              breakpoints: [320, 480, 640, 756, 920]
            )
          }
        }
      }
    }
    areas {
      nodes {
        id
        name
      }
    }
  }
`
