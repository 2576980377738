import React from "react"
import tw from "twin.macro"
import Spacing from "../../spacing"
import PropTypes from "prop-types"

const QuoteSource = ({ content, source, sourceUrl, space, id }) => {
  return (
    <Spacing
      space={space}
      defaultStyles={tw`mb-24 sm:mb-28 md:mb-32 lg:mb-40`}
      id={id}
    >
      <blockquote tw="mb-4 -ml-2 border-l-[3px] border-lilac-500 pl-2 text-lg font-bold before:content-['„'] after:content-['“'] sm:-ml-4 sm:pl-4 md:-ml-6 md:pl-6 md:text-xl lg:mb-6 lg:text-2xl">
        {content}
      </blockquote>
      <cite tw="text-sm not-italic lg:text-base">
        <span>Quelle: </span>
        <a
          href={sourceUrl}
          tw="text-link"
          title={source}
          target="_blank"
          rel="noreferrer noopener"
        >
          {source}
        </a>
      </cite>
    </Spacing>
  )
}

QuoteSource.propTypes = {
  id: PropTypes.string,
  space: PropTypes.string,
  content: PropTypes.string,
  source: PropTypes.string,
  sourceUrl: PropTypes.string,
}

export default QuoteSource
