import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"
import Spacing from "../spacing"
import Copyright from "../copyright"

const Image = ({ image, space, caption, background, id }) => {
  const contentImage = {
    data: image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: image?.altText || ``,
  }
  const hasCopyright =
    image?.imageCopyright.photographer || image?.imageCopyright.rights

  return (
    <Spacing
      space={space}
      defaultStyles={tw`mb-24 sm:mb-28 md:mb-32 lg:mb-40`}
      background={background}
      id={id}
    >
      {contentImage?.data && (
        <figure>
          <div tw="relative">
            {!!hasCopyright && (
              <Copyright
                photographer={image?.imageCopyright.photographer}
                rights={image?.imageCopyright.rights}
              />
            )}
            <GatsbyImage
              image={contentImage.data}
              alt={contentImage.alt}
              tw=""
              load="lazy"
            />
          </div>
          {caption && (
            <figcaption tw="mt-4 text-sm md:text-base">{caption}</figcaption>
          )}
        </figure>
      )}
    </Spacing>
  )
}

Image.propTypes = {
  image: PropTypes.object.isRequired,
  space: PropTypes.string,
  id: PropTypes.string,
  caption: PropTypes.string,
  background: PropTypes.string,
}

export default Image
