import React, { useState } from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import parse from "html-react-parser"
import Modal from "react-modal"
import Icon from "./icon"
import PlusIconSm from "../images/svgs/plus-sm.svg"
import PlusIcon from "../images/svgs/plus.svg"

const DescriptionModal = ({
  title,
  content,
  modalStyles = null,
  contentStyles = null,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  function openModal() {
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }
  Modal.setAppElement("#___gatsby")
  const defaultModalStyles = tw`bg-lilac-500/75 z-30`
  const defaultContentStyles = tw`bg-sand-500 text-anthracite-500 text-2xl max-w-3xl mx-auto py-3 px-4 left-4 right-4`

  return (
    <>
      <button
        tw="flex items-center p-4 text-anthracite-500 hover:text-white motion-safe:transition-colors motion-safe:duration-300 motion-safe:ease-in-out lg:p-5"
        onClick={openModal}
      >
        <span tw="mr-2 whitespace-nowrap text-lg font-bold uppercase !leading-none tracking-widest">
          Mehr Infos
        </span>
        <Icon svg={PlusIconSm} tw="h-4 w-4 flex-shrink-0" />
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel={`Steckbrief von ${title}`}
        style={{
          overlay: modalStyles ?? defaultModalStyles,
          content: contentStyles ?? defaultContentStyles,
        }}
      >
        <main role="main">
          <div tw="flex justify-end md:pt-2 md:pr-2">
            <button
              tw="fixed z-10 flex items-center  hover:-rotate-90 focus:-rotate-90 motion-safe:transition-transform motion-safe:duration-300 motion-safe:ease-in-out"
              onClick={closeModal}
            >
              <span tw="sr-only">Mehr Infos</span>
              <div tw="flex h-10 w-10 flex-shrink-0 rotate-45 items-center justify-center rounded-full group-hover:bg-lilac-500 group-focus:bg-lilac-500 md:h-9 md:w-9 ">
                <Icon
                  svg={PlusIcon}
                  tw="h-6 w-6 fill-current text-lilac-500 group-hover:text-white group-focus:text-white md:h-5 md:w-5"
                />
              </div>
            </button>
          </div>

          <h1 tw="mb-3 mt-2 text-xl font-bold md:p-5 md:pb-0 lg:mb-7 lg:mt-8 lg:p-7 lg:pb-0 lg:text-2xl">
            {title}
          </h1>
          {!!content && (
            <section
              itemProp="articleBody"
              className="wp-content"
              tw="bg-sand-500 md:p-5 md:pt-0 lg:p-7 lg:pt-0"
            >
              {parse(content)}
            </section>
          )}
        </main>
      </Modal>
    </>
  )
}

DescriptionModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  modalStyles: PropTypes.object,
  contentStyles: PropTypes.object,
}

export default DescriptionModal
