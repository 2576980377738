import React from "react"
import PropTypes from "prop-types"
import "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"
import Icon from "../../components/icon"
import ArrowDownStroke from "../../images/svgs/arrow-down-stroke.svg"
import Copyright from "../copyright"

const DownloadLandscapeCard = ({ image, title, file }) => {
  const contentImage = {
    data: image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: image?.altText || ``,
  }
  const hasCopyright =
    image?.imageCopyright.photographer || image?.imageCopyright.rights

  return (
    <figure>
      <div tw="relative mb-4 bg-sand-500 aspect-ratio aspect-ratio-4/3">
        {!!hasCopyright && (
          <Copyright
            photographer={image?.imageCopyright.photographer}
            rights={image?.imageCopyright.rights}
          />
        )}
        {contentImage?.data && (
          <GatsbyImage
            image={contentImage.data}
            alt={contentImage.alt}
            tw="object-contain aspect-ratio-item"
            load="lazy"
          />
        )}
      </div>
      <figcaption>
        <a
          href={file?.localFile?.publicURL}
          tw="flex items-start text-lg font-bold lg:text-xl"
          className="group"
          download
        >
          <Icon
            svg={ArrowDownStroke}
            tw="mr-3 mt-1 h-6 w-5 flex-shrink-0 md:mr-4 md:h-6 md:w-5"
            title="Download"
          />
          <span tw="underline-offset-4 group-hover:underline group-hover:decoration-3">
            {title}{" "}
            {file?.localFile?.extension && (
              <span tw="uppercase">({file?.localFile?.extension})</span>
            )}
          </span>
        </a>
      </figcaption>
    </figure>
  )
}

DownloadLandscapeCard.propTypes = {
  title: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
  image: PropTypes.object,
}

export default DownloadLandscapeCard
