import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import TeamMemberCard from "../../cards/team-member"
import BlockTitle from "../../block-title"
import Spacing from "../../spacing"

const TeamList = ({
  id,
  title,
  items,
  titleTag,
  titleStyle,
  titleAnchor,
  space,
  background,
  isCustomSpace = false,
  isCustomBackground = false,
  isCustomTitleStyle = false,
  isCustomTitleTag = false,
  isAnchor = false,
}) => {
  return (
    <Spacing
      id={id}
      space={isCustomSpace && space}
      defaultStyles={tw`mb-8 sm:mb-12 md:mb-16 lg:mb-20`}
      background={isCustomBackground && background}
    >
      {!!title && (
        <BlockTitle
          title={title}
          tag={isCustomTitleTag && titleTag}
          style={isCustomTitleStyle && titleStyle}
          anchor={isAnchor && titleAnchor}
        />
      )}
      <ul tw="grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
        {items.map((item, index) => (
          <li key={`team-${index}`} tw="inline-grid">
            <TeamMemberCard {...item} />
          </li>
        ))}
      </ul>
    </Spacing>
  )
}

TeamList.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array.isRequired,
  space: PropTypes.string,
  titleStyle: PropTypes.string,
  titleTag: PropTypes.string,
  titleAnchor: PropTypes.string,
  isCustomSpace: PropTypes.bool,
  isCustomBackground: PropTypes.bool,
  isCustomTitleStyle: PropTypes.bool,
  isCustomTitleTag: PropTypes.bool,
  isAnchor: PropTypes.bool,
}

export default TeamList
