import React, { useState } from "react"
import tw from "twin.macro"
import parse from "html-react-parser"
import uniqueId from "lodash/uniqueId"
// import Indent from "../indent"
import plus from "../../images/svgs/plus.svg"
import minus from "../../images/svgs/minus.svg"
import Icon from "../icon"
import "../../css/gutenberg.css"
import Spacing from "../spacing"
import PropTypes from "prop-types"
import "../../css/gutenberg.css"

const Accordion = ({ accordion, space, background, id }) => {
  const plusIcon = plus
  const minusIcon = minus

  const [accordionItems, setAccordionItems] = useState(
    accordion.map(({ id, title, content, interamt }) => ({
      id: id || uniqueId("accordion-"),
      title: title,
      content: content,
      interamt: interamt?.interamtid,
      isOpen: false,
    }))
  )

  const toggleAccordionItems = index => {
    setAccordionItems(
      accordionItems.map((accordionItem, i) => {
        if (index === i) {
          accordionItem.isOpen = !accordionItem.isOpen
        }
        return accordionItem
      })
    )
  }

  return (
    <Spacing
      space={space}
      defaultStyles={tw`mb-20 sm:mb-22 md:mb-26 lg:mb-32`}
      background={background}
      id={id}
    >
      <ul tw="border-b-3 border-b-lilac-500">
        {accordionItems.map(
          ({ id, title, content, interamt, isOpen }, index) => (
            <li key={id} tw="flex flex-col border-t-3 border-t-lilac-500">
              <button
                tw="flex w-full flex-nowrap items-center justify-between px-4 lg:px-6"
                id={`${id}-header`}
                aria-controls={`${id}-panel`}
                aria-expanded={isOpen}
                onClick={() => toggleAccordionItems(index)}
                className="group"
              >
                <div tw="block w-11/12 py-4 pr-6 text-left text-xl font-bold md:py-6 md:text-2xl">
                  {title}
                </div>
                <div tw="flex h-10 w-10 flex-shrink-0 items-center justify-center group-hover:bg-lilac-500 group-focus:bg-lilac-500  md:h-9  md:w-9">
                  <Icon
                    title={isOpen ? "Details Verbergen" : "Details Anzeigen"}
                    svg={isOpen ? minusIcon : plusIcon}
                    tw="h-6 w-6 text-lilac-500 group-hover:text-white group-focus:text-white md:h-5 md:w-5"
                  />
                  <span tw="sr-only">
                    {isOpen ? "Details Verbergen" : "Details Anzeigen"}
                  </span>
                </div>
              </button>
              <div
                id={`${id}-panel`}
                aria-labelledby={`${id}-header`}
                hidden={isOpen ? "" : true}
                tw="px-4 pb-4 pt-4 lg:px-6 lg:pb-6 lg:pt-6"
              >
                <div className="wp-content" tw="">
                  {parse(content)}

                  {interamt && (
                    <a
                      href={`https://www.interamt.de/koop/app/stelle?id=${interamt}`}
                      className="wp-block-button__link"
                      tw="my-5"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Jetzt bewerben
                    </a>
                  )}
                </div>
              </div>
            </li>
          )
        )}
      </ul>
    </Spacing>
  )
}

Accordion.propTypes = {
  space: PropTypes.string,
  id: PropTypes.string,
}

export default Accordion
