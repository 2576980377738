import React from "react"
// import PropTypes from 'prop-types'
import tw from "twin.macro"

const Indent = ({ children, lg = false }) => {
  return (
    <div tw="grid grid-cols-12 gap-6 md:gap-5">
      <div
        css={[
          tw`col-span-12 col-start-1 lg:col-start-2`,
          lg && tw`col-span-12 col-start-1 lg:col-span-10 lg:col-start-2`,
          !lg && tw`lg:col-span-9 lg:col-start-3`,
        ]}
      >
        {children}
      </div>
    </div>
  )
}

// Container.propTypes = {}

export default Indent
