import React from "react"
import PropTypes from "prop-types"
import "twin.macro"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Copyright from "../copyright"

const PersonCard = ({ image, name, description }) => {
  const contentImage = {
    data: image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: image?.altText || ``,
  }
  const hasCopyright =
    image?.imageCopyright.photographer || image?.imageCopyright.rights

  return (
    <figure>
      <div tw="relative mb-6 bg-lilac-300 aspect-ratio aspect-ratio-3/4">
        {!!hasCopyright && (
          <Copyright
            photographer={image?.imageCopyright.photographer}
            rights={image?.imageCopyright.rights}
          />
        )}
        {contentImage?.data ? (
          <GatsbyImage
            image={contentImage.data}
            alt={contentImage.alt}
            tw="object-contain aspect-ratio-item"
            load="lazy"
          />
        ) : (
          <div tw="aspect-ratio aspect-ratio-3/4">
            <div tw="aspect-ratio-item">
              <StaticImage
                src="../../images/placeholder_3_4.jpg"
                alt=""
                tw="h-full w-full object-cover"
              />
            </div>
          </div>
        )}
      </div>
      <figcaption tw="text-lg md:text-xl">
        <span tw="block font-bold">{name}</span>
        {description && <span>{description}</span>}
      </figcaption>
    </figure>
  )
}

PersonCard.propTypes = {
  image: PropTypes.object,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
}

export default PersonCard
