import React from "react"
import { graphql } from "gatsby"
import "twin.macro"
// import Container from "../container"
import Image from "../blocks/image"
import Text from "../blocks/text"
import Accordion from "../blocks/accordion"
import AccordionSubtitle from "../blocks/accordion-subtitle"
import DownloadLandscapeList from "./lists/download-landscape"
import DownloadList from "./lists/download"
import MediaList from "./lists/media"
import PersonList from "./lists/person"
import TeamList from "./lists/team"
import QuoteSource from "./quotes/source"
import Animation from "../blocks/animation"
import Teaser from "../blocks/teaser"

const Blocks = ({ blocks, indent }) => {
  return (
    <>
      {blocks.map((block, index) => (
        <div id={block.slug} key={`block-${index}`}>
          {block.fieldGroupName.includes("_Blocks_Blocks_Text") && (
            <Text
              {...block}
              id={`text-${index}`}
              index={index}
              indent={indent}
            />
          )}
          {block.fieldGroupName.includes("_Blocks_Blocks_Images") && (
            <Image {...block} id={`image-${index}`} indent={indent} />
          )}
          {block.fieldGroupName.includes(
            "_Blocks_Blocks_Downloadlandscapelist"
          ) && (
            <DownloadLandscapeList
              {...block}
              id={`download-landscape-list-${index}`}
            />
          )}
          {block.fieldGroupName.includes("_Blocks_Blocks_Downloadlist") && (
            <DownloadList {...block} id={`download-list-${index}`} />
          )}
          {block.fieldGroupName.includes("_Blocks_Blocks_Podcastlist") && (
            <MediaList {...block} id={`media-list-${index}`} />
          )}
          {block.fieldGroupName.includes("_Blocks_Blocks_Personlist") && (
            <PersonList {...block} id={`person-list-${index}`} />
          )}
          {block.fieldGroupName.includes("_Blocks_Blocks_Teamlist") && (
            <TeamList {...block} id={`team-member-list-${index}`} />
          )}
          {block.fieldGroupName.includes("_Blocks_Blocks_Animation") && (
            <Animation {...block} id={`animation-${index}`} />
          )}
          {block.fieldGroupName.includes("_Blocks_Blocks_Teaser") && (
            <Teaser {...block} id={`Teaser-${index}`} indent={indent} />
          )}
          {block.fieldGroupName.includes("_Blocks_Blocks_Accordion") &&
            block.type === "title" && (
              <Accordion {...block} id={`accordeon-list-${index}`} />
            )}
          {block.fieldGroupName.includes("_Blocks_Blocks_Accordion") &&
            block.type === "titlesubtitle" && (
              <AccordionSubtitle {...block} id={`accordeon-${index}`} />
            )}
          {block.fieldGroupName.includes("_Blocks_Blocks_Quotesource") && (
            <QuoteSource {...block} id={`quote-source-${index}`} />
          )}
        </div>
      ))}
    </>
  )
}

export default Blocks

export const pageQuery = graphql`
  fragment BlocksFragment on WpPage {
    blocks {
      blocks {
        ... on WpPage_Blocks_Blocks_Text {
          fieldGroupName
          isAnchor
          isCustomBackground
          isCustomSpace
          isCustomTitleStyle
          isCustomTitleTag
          titleStyle
          titleTag
          titleAnchor
          titleStyle
          title
          content
          background
          space
        }
        ... on WpPage_Blocks_Blocks_Images {
          fieldGroupName
          isCustomSpace
          space
          caption
          image {
            altText
            imageCopyright {
              photographer
              rights
            }
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 60
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                  breakpoints: [320, 480, 640, 756, 920, 1100, 1240, 1480]
                )
              }
            }
          }
        }
        ... on WpPage_Blocks_Blocks_Quotesource {
          fieldGroupName
          content
          source
          sourceUrl
          isCustomSpace
          space
        }
        ... on WpPage_Blocks_Blocks_Accordion {
          fieldGroupName
          isCustomSpace
          space
          type
          accordion {
            content
            title
            subtitle
            fieldGroupName
          }
        }
        ... on WpPage_Blocks_Blocks_Animation {
          fieldGroupName
          isCustomSpace
          animation
          animationDescription
          animationFallbackImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 60
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                  breakpoints: [320, 480, 640, 756, 920, 1100, 1240, 1480]
                )
              }
            }
          }
          space
        }
        ... on WpPage_Blocks_Blocks_Teaser {
          fieldGroupName
          isCustomSpace
          title
          content
          link {
            url
            title
            target
          }
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 60
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                  breakpoints: [320, 480, 640, 756, 920, 1100, 1240, 1480]
                )
              }
            }
          }
          space
        }
        ... on WpPage_Blocks_Blocks_Teamlist {
          fieldGroupName
          isAnchor
          isCustomBackground
          isCustomSpace
          isCustomTitleStyle
          isCustomTitleTag
          titleStyle
          titleTag
          titleAnchor
          titleStyle
          title
          background
          space
          items {
            member {
              ... on WpMember {
                id
                title
                member {
                  email
                  firstname
                  jobtitle
                  lastname
                  phone
                  title
                }
                content
                featuredImage {
                  node {
                    altText
                    imageCopyright {
                      photographer
                      rights
                    }
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 60
                          placeholder: BLURRED
                          layout: FULL_WIDTH
                          breakpoints: [320, 480, 640, 756, 920]
                        )
                      }
                    }
                  }
                }
                areas {
                  nodes {
                    id
                    name
                  }
                }
              }
            }
            fieldGroupName
          }
        }
        ... on WpPage_Blocks_Blocks_Downloadlandscapelist {
          fieldGroupName
          isAnchor
          isCustomBackground
          isCustomSpace
          isCustomTitleStyle
          isCustomTitleTag
          titleStyle
          titleTag
          titleAnchor
          titleStyle
          title
          background
          space
          items {
            title
            file {
              title
              localFile {
                extension
                publicURL
              }
            }
            image {
              altText
              imageCopyright {
                photographer
                rights
              }
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 60
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    breakpoints: [320, 480, 640, 756, 920, 1100, 1240, 1480]
                  )
                }
              }
            }
          }
        }
        ... on WpPage_Blocks_Blocks_Downloadlist {
          fieldGroupName
          isAnchor
          isCustomBackground
          isCustomSpace
          isCustomTitleStyle
          isCustomTitleTag
          titleStyle
          titleTag
          titleAnchor
          titleStyle
          title
          background
          space
          items {
            title
            file {
              title
              localFile {
                extension
                publicURL
              }
            }
            image {
              altText
              imageCopyright {
                photographer
                rights
              }
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 60
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    breakpoints: [320, 480, 640, 756, 920, 1100, 1240, 1480]
                  )
                }
              }
            }
          }
        }
        ... on WpPage_Blocks_Blocks_Podcastlist {
          fieldGroupName
          isAnchor
          isCustomBackground
          isCustomSpace
          isCustomTitleStyle
          isCustomTitleTag
          titleStyle
          titleTag
          titleAnchor
          titleStyle
          title
          background
          space
          items {
            title
            image {
              altText
              imageCopyright {
                photographer
                rights
              }
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 60
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    breakpoints: [320, 480, 640, 756, 920, 1100, 1240, 1480]
                  )
                }
              }
            }
          }
        }
        ... on WpPage_Blocks_Blocks_Personlist {
          fieldGroupName
          isAnchor
          isCustomBackground
          isCustomSpace
          isCustomTitleStyle
          isCustomTitleTag
          titleStyle
          titleTag
          titleAnchor
          titleStyle
          title
          background
          space
          items {
            description
            name
            image {
              altText
              imageCopyright {
                photographer
                rights
              }
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 60
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    breakpoints: [320, 480, 640, 756, 920, 1100, 1240, 1480]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
  fragment JobBlocksFragment on WpJob {
    blocks {
      blocks {
        ... on WpJob_Blocks_Blocks_Text {
          fieldGroupName
          isAnchor
          isCustomBackground
          isCustomSpace
          isCustomTitleStyle
          isCustomTitleTag
          titleStyle
          titleTag
          titleAnchor
          titleStyle
          title
          content
          background
          space
        }
        ... on WpJob_Blocks_Blocks_Images {
          fieldGroupName
          isCustomSpace
          space
          image {
            altText
            imageCopyright {
              photographer
              rights
            }
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 60
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                  breakpoints: [320, 480, 640, 756, 920, 1100, 1240, 1480]
                )
              }
            }
          }
        }
        ... on WpJob_Blocks_Blocks_Accordion {
          fieldGroupName
          space
          type
          accordion {
            content
            title
            subtitle
            fieldGroupName
          }
        }
      }
    }
  }
`
